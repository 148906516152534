<template>
  <div class="lg:container lg:mx-auto">
    <!------------------------- Gram header ----------------------------->
    <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
      <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
        <div class="lg:grid lg:grid-cols-12 lg:gap-[36px] ">
        <div class="col-span-6 lg:mb-0 mb-[36px] flex flex-col justify-center">
          <h1 class="text-[#333] text-[34px] lg:text-[44px] font-bold leading-10">
            Всегда та поездка,<br class="hidden lg:block" />
            которую вы хотите
          </h1>
          <p class="pt-5">Закажите поездку, садитесь и расслабьтесь.</p>
          <a href="#">
            <div
              class="
                border
                cursor-pointer
                border-black
                bg-black
                w-[170px]
                text-white
                flex
                justify-center
                items-center
                py-3
                rounded-lg
                mt-7
              "
            >
              Скоро
            </div>
          </a>
        </div>
        <div class="lg:col-span-6 lg:mb-16">
          <img class="block" src="@/assets/img/81.jpg" alt="Изображение" />
        </div>
        </div>
      </div>
    </div>
    <!--x---------------------- Gram header --------------------------x-->
    <section>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="mb-[50px]">
            <h1 class="font-bold text-2xl lg:text-4xl text-[#333333]">
              Зачем использовать приложение Gram?
            </h1>
            <!-- <div class="pt-14 flex justify-center">
              <img class="block w-[600px]" src="@/assets/img/321.png" alt="" />
            </div> -->
          </div>
          <div
            class="
              lg:grid lg:grid-cols-12 lg:gap-[36px]
              md:grid md:grid-cols-12 md:gap-[36px]
            "
          >
            <div class="lg:col-span-4 md:col-span-6 mb-[36px] lg:mb-0">
              <img class="mb-[20px] w-[40px]" src="@/assets/img/icon/speed.svg" alt="" />
              <div>
                <h4 class="text-[18px] font-medium leading-[24px] mb-[12px]">
                  Быстро и просто
                </h4>
              </div>
              <div class="text-[16px] font-normal leading-[24px] mb-[20px]">
                <p>Подача за считанные минуты</p>
              </div>
            </div>
            <div class="lg:col-span-4 md:col-span-6 mb-[36px] lg:mb-0">
              <img class="mb-[20px] w-[40px]" src="@/assets/img/icon/majesticons_coins-line.svg" alt="" />
              <div>
                <h4 class="text-[18px] font-medium leading-[24px] mb-[12px]">
                  Отличные цены
                </h4>
              </div>
              <div class="text-[16px] font-normal leading-[24px] mb-[20px]">
                <p>
                  Мы стремимся предлагать лучшие цены на поездки в вашем городе
                </p>
              </div>
            </div>
            <div class="lg:col-span-4 md:col-span-6  lg:mb-0">
              <img class="mb-[20px] w-[40px]" src="@/assets/img/icon/mdi_clock-outline.svg" alt="" />
              <div>
                <h4 class="text-[18px] font-medium leading-[24px] mb-[12px]">
                  Удобно
                </h4>
              </div>
              <div class="text-[16px] font-normal leading-[24px] mb-[20px]">
                <p>Доберитесь куда угодно - всего в пару касаний</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <div  class="ml-3 lg:ml-16 mt-32  mx-3 lg:mx-0">
          <div>
              <h1 class="font-bold text-2xl lg:text-4xl text-[#333333]">Ваша безопасность имеет значение</h1>
              <p class="font-normal text-base mt-6">Душевное спокойствие встроено в ваш опыт.</p>
              <div class="lg:flex lg:justify-between pt-14">
                  <ul>
                      <li><img class="w-12 h-12" src="@/assets/img/icon/Safety-Center.svg"></li>
                      <li class="font-medium text-lg pt-[15px]">Функции безопасности</li>
                      <li class=" lg:w-[300px] lg:h-[72px] pt-[15px]">Расскажите своим близким, где вы находитесь. Получите помощь одним нажатием кнопки. Технологии делают путешествия более безопасными, чем когда-либо прежде.</li>
                      <li class="pt-24 hidden lg:block">Узнайте больше о безопасности</li>
                  </ul>
                  <ul class="pt-14 lg:pt-0">
                      <li><img class="w-12 h-12" src="@/assets/img/icon/Community-Guidelines.svg"></li>
                      <li class="font-medium text-lg pt-[15px]">Инклюзивное сообщество</li>
                      <li class="lg:w-[318px] lg:h-12 pt-[15px]">Мы — миллионы пассажиров и водителей, которые разделяют принципы сообщества и зависят друг от друга, чтобы поступать правильно.</li>
                  </ul>
                  <ul class="pt-14 lg:pt-0">
                      <li><img class="w-12 h-12" src="@/assets/img/icon/24_7-Support.svg"></li>
                      <li class="font-medium text-lg pt-[15px]">Помогите, если вам это нужно</li>
                      <li class="lg:w-[318px] lg:h-12 pt-[15px]">Получите круглосуточную поддержку в приложении по любым вопросам или вопросам безопасности, которые могут у вас возникнуть.</li>
                  </ul>
                  <p class="pt-12 lg:hidden block">Узнайте больше о безопасности</p>
              </div>
          </div>
      </div> -->
    <!-- <section  class="ml-3 lg:ml-16 mb-[60px] mt-32 mx-3 lg:mx-10">
          <div>
              <h1 class="font-bold text-2xl lg:text-4xl text-[#333333]">Способы катания в этом районе</h1>
          </div>
          <div class="flex">
              <div @click="formPage = 1" :class="`flex flex-col justify-center items-center px-[12px] pb-[20px] pt-[28px] max-w-[105px] w-full cursor-pointer ${formPage == 1 ? 'border-black border-b-[4px]': ''}`"><p :class="`text-center m-[8px] flex justify-center items-center duration-100 font-medium ${formPage == 1 ? 'opacity-70': ''}`">Эконом</p>
              </div>
              <div @click="formPage = 2" :class="`flex flex-col justify-center items-center px-[12px] pb-[20px] pt-[28px] max-w-[105px] w-full cursor-pointer ${formPage == 2 ? 'border-black border-b-[4px]': ''}`"><p :class="`text-center m-[8px] flex justify-center items-center duration-100 font-medium ${formPage == 2 ? 'opacity-70': ''}`">Премиум</p></div>
              <div @click="formPage = 3" :class="`flex flex-col justify-center items-center px-[12px] pb-[20px] pt-[28px] max-w-[105px] w-full cursor-pointer ${formPage == 3 ? 'border-black border-b-[4px]': ''}`"><p :class="`text-center m-[8px] flex justify-center items-center duration-100 font-medium ${formPage == 3 ? 'opacity-70': ''}`">Другое</p></div>
          </div>
          <div class="hidden md:block lg:block">
              <div>
                  <div v-show="formPage == 1" class="flex">
                      <div>
                          <img src="../assets/img/economCar.svg" alt="IMAGE" class="w-[350px]">
                          <div class="flex">
                              <h1 class="font-medium text-lg">GramX</h1>
                              <div class="border bg-black border-none w-14 text-white rounded-[30px] py-[3px] px-[15px] ml-[10px]">1-4</div>
                          </div>
                          <p class="pt-[13px]">Доступные поездки, все для себя</p>
                      </div>
                  </div>
              </div>
              <div>
                  <div v-show="formPage == 2" class="flex">
                      <div>
                        <img src="../assets/img/businessCar.svg" alt="IMAGE" class="w-[250px] m-[50px]">
                          <div class="flex">
                              <h1 class="font-medium text-lg">Premium</h1>
                              <div class="border bg-black border-none w-14 text-white rounded-[30px] py-[3px] px-[15px] ml-[10px]">1-4</div>
                          </div>
                          <p class="pt-[13px]">Роскошные поездки</p>
                      </div>
                  </div>
              </div>
              <div v-show="formPage == 3" class="">
                  <img src="../assets/img/minivanCar.svg" alt="IMAGE" class="w-[150px] m-[70px]">
                  <div class="flex">
                              <h1 class="font-medium text-lg">WAV</h1>
                              <div class="border bg-black border-none w-14 text-white rounded-[30px] py-[3px] px-[15px] ml-[10px]">1-4</div>
                          </div>
                          <p class="pt-[13px]">Машина для большой компании</p>
              </div>
          </div>
          <div class=" overflow-hidden my-0 mx-auto">
              <div class="flex " :style="{ 'margin-left': '-' + (100 * currentSlideIndex) + '%'}">
                  <div class="relative" v-for="item in sliderItems" :key="item.id">
                      <img class="" :src=" require('@/assets/img/images/' + item.img) " alt="">
                  </div>
              </div>
              <div>
                  <div @click="prevSlide" class="border-black border px-3 py-3 rounded-[50%] w-[50px]" >Prev</div>
                  <div @click="nextSlide" class="border-black border px-3 py-3 rounded-[50%] w-[50px]" >Next</div>
              </div>
          </div>
          <div class="block md:hidden lg:hidden">
              <div v-show="formPage == 1">
                  <div v-show="currentSlideIndex === 0">
                      <img src="@/assets/img/UberX.webp" alt="">
                      <div class="flex">
                              <h1 class="font-medium text-lg">GramX</h1>
                              <div class="border bg-black border-none w-14 text-white rounded-[30px] py-[3px] px-[15px] ml-[10px]">1-4</div>
                      </div>
                      <p class="pt-[13px]">Доступные поездки, все для себя</p>
                  </div>
                  <div v-show="currentSlideIndex === 1">
                      <img src="@/assets/img/UberXL.webp" alt="">
                      <div class="flex">
                              <h1 class="font-medium text-lg">GramXL</h1>
                              <div class="border bg-black border-none w-14 text-white rounded-[30px] py-[3px] px-[15px] ml-[10px]">1-6</div>
                      </div>
                      <p class="pt-[13px]">Доступные аттракционы для групп до 6 человек</p>
                  </div>
              </div> 
              <div v-show="formPage == 2">
                  <div v-show="currentSlideIndex === 0">
                      <img src="@/assets/img/Black.webp" alt="">
                      <div class="flex">
                              <h1 class="font-medium text-lg">GramX</h1>
                              <div class="border bg-black border-none w-14 text-white rounded-[30px] py-[3px] px-[15px] ml-[10px]">1-4</div>
                      </div>
                      <p class="pt-[13px]">Доступные поездки, все для себя</p>
                  </div>
                  <div v-show="currentSlideIndex === 1">
                      <img src="@/assets/img/BlackSUV.webp" alt="">
                      <div class="flex">
                              <h1 class="font-medium text-lg">GramXL</h1>
                              <div class="border bg-black border-none w-14 text-white rounded-[30px] py-[3px] px-[15px] ml-[10px]">1-6</div>
                      </div>
                      <p class="pt-[13px]">Доступные аттракционы для групп до 6 человек</p>
                  </div>
              </div> 
              <div v-if="formPage !== 3" class="flex justify-end items-end mt-16 mr-[44px]">
                  <div class="flex justify-center items-center">
                      <p class="font-medium text-[18px] mr-[22px]">{{number}}/2</p>
                      <div @click="prevSlide" class="border-black border px-5 py-5 rounded-[50%] flex items-center cursor-pointer" ><img src="@/assets/img/VectorL.svg" alt=""></div>
                      <div @click="nextSlide" class="border-black border px-5 py-5 rounded-[50%] flex items-center ml-2 cursor-pointer" ><img src="@/assets/img/VectorR.svg" alt=""></div>
                  </div>
              </div>
              <div v-show="formPage == 3">
                  <div>
                      <img src="@/assets/img/WAV.webp" alt="">
                      <div class="flex">
                              <h1 class="font-medium text-lg">WAV</h1>
                              <div class="border bg-black border-none w-14 text-white rounded-[30px] py-[3px] px-[15px] ml-[10px]">1-4</div>
                      </div>
                      <p class="pt-[13px]">Доступные для инвалидных колясок аттракционы</p>
                  </div>
                
              </div> 
          </div>
      </section> -->
    <!-- <div class="ml-3 lg:ml-16 mt-32 mx-3 lg:mx-10">
          <div class="lg:pl-16">
              <h1 class="font-bold text-2xl lg:text-4xl text-[#333333]">Везде, что вы путешествуете</h1>
              <div class="md:flex lg:flex items-center mt-[70px] lg:mx-3">
                  <ul>
                      <li><img class="lg:max-w-[496px] lg:max-h-[279px]" src="@/assets/img/D_Ride_cities2x.webp" alt=""></li>
                      <li class="pt-5">10,000+ города</li>
                      <li class="pt-[14px] lg:w-[418px]">Приложение доступно в тысячах городов по всему миру, поэтому вы можете заказать поездку, даже находясь далеко от дома.</li>
                      <li class="pt-[30px] pb-8">Поиск городов</li>
                  </ul>
                  <ul class="md:pl-10 lg:pl-10">
                      <li><img class="lg:max-w-[496px] lg:max-h-[279px]" src="@/assets/img/D_Rider_Featured-22x.webp" alt=""></li>
                      <li class="pt-5">600+ аэропортов</li>
                      <li class="pt-[14px] lg:w-[418px]">Вы можете добраться до и из большинства крупных аэропортов. Запланируйте поездку в аэропорт, чтобы не о чем беспокоиться.</li>
                      <li class="pt-[30px] pb-8">Поиск аэропортов</li>
                  </ul>
              </div>
          </div>
      </div> -->
    <section>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <div class="lg:grid lg:grid-cols-12 lg:gap-[36px] ">
            <div class="col-span-6 lg:mr-auto lg:mt-0 mt-[30px]">
              <img class="" src="@/assets/img/taxigram_app.jpg" alt="" />
            </div>
            <div class="col-span-5 lg:mt-0 mt-[20px]">
              <h2 class="lg:text-4xl text-2xl font-bold lg:mb-[40px]">
                Скачайте приложение Gram
              </h2>
              <p class="lg:w-[425px] pt-[26px]">
                Приложение Gram дает вам возможность добраться туда, куда вы
                хотите.
              </p>
              <div class="flex flex-wrap">
                <a href="#">
                  <div
                    class="
                      no-underline
                      border-none
                      rounded-[10px]
                      leading-[20px]
                      font-medium
                      hover:opacity-80
                      mt-[12px]
                      cursor-pointer
                      duration-200
                      px-[50px]
                      py-[14px]
                      bg-black
                      text-white
                    "
                  >
                    Скоро
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- <section>
      <div class="lg:max-w-7xl w-full ml-auto mr-auto text-[#333333]">
        <div class="lg:p-[64px] md:px-[32px] md:py-[56px] py-[40px] px-[24px]">
          <h2
            class="
              lg:text-[36px]
              md:text-[32px]
              col-span-11
              md:leading-[40px]
              text-[28px]
              leading-[36px]
              lg:leading-[44px]
              font-bold
            "
          >
            Часто задаваемые вопросы
          </h2>
          <div class="w-full mt-[50px]">
            <div>
              <div
                @click="firstQuestion = !firstQuestion"
                class="
                  flex
                  justify-between
                  items-center
                  pb-[20px]
                  select-none
                  cursor-pointer
                "
              >
                <h4 class="lg:text-[20px] text-[16px] font-medium">
                  Как создать аккаунт для заказа такси?
                </h4>
                <img
                  :class="`lg:w-[18px] duration-300 ${
                    firstQuestion ? 'rotate-180' : ''
                  }`"
                  src="@/assets/img/V2.svg"
                  alt=""
                />
              </div>
              <hr />
              <div
                :class="`${
                  firstQuestion ? 'lg:h-[50px] h-[100px]' : 'h-0'
                } lg:text-[18px] my-[20px] duration-300 overflow-hidden`"
              >
                Для создание аккаунта вам нужно скачать приложение Gram из
                Google Play, или зарегистрироватся на сайте
                <a class="font-medium underline" href="#"
                  >Скоро</a
                >
              </div>
            </div>
          </div>
          <div class="w-full mt-[30px]">
            <div>
              <div
                @click="thirdQuestion = !thirdQuestion"
                class="
                  flex
                  h-auto
                  w-full
                  justify-between
                  items-center
                  pb-[20px]
                  select-none
                  cursor-pointer
                "
              >
                <h4 class="lg:text-[20px] text-[16px] font-medium">
                  Как заказать поездку?
                </h4>
                <img
                  :class="`lg:w-[18px] duration-300 ${
                    thirdQuestion ? 'rotate-180' : ''
                  }`"
                  src="@/assets/img/V2.svg"
                  alt=""
                />
              </div>
              <hr />
              <div
                :class="`${
                  thirdQuestion ? 'lg:h-[100px] h-[150px]' : 'h-0'
                } my-[20px] duration-300 overflow-hidden`"
              >
                Откройте приложение или войдите на сайт
                <a class="font-medium underline" href="https://play.google.com/store/apps/details?id=com.gram.client"
                  >m.gram.tj</a
                >
                и введите пуникт назначения. Затем выберите вариант поездки,
                который лучше всего соответствует вышим потребностям.
                Подтвердите заказ, нажав "Заказать".
              </div>
            </div>
          </div>
          <div class="w-full mt-[30px]">
            <div>
              <div
                @click="secondQuestion = !secondQuestion"
                class="
                  flex
                  h-auto
                  w-full
                  justify-between
                  items-center
                  pb-[20px]
                  select-none
                  cursor-pointer
                "
              >
                <h4 class="lg:text-[20px] mr-[10px] text-[16px] font-medium">
                  Могу ли я заказть такси без мобильного приложения?
                </h4>
                <img
                  :class="`lg:w-[18px] duration-300 ${
                    secondQuestion ? 'rotate-180' : ''
                  }`"
                  src="@/assets/img/V2.svg"
                  alt=""
                />
              </div>
              <hr />
              <div
                :class="`${
                  secondQuestion ? 'lg:h-[50px] h-[100px]' : 'h-0'
                } my-[20px] duration-300 overflow-hidden`"
              >
                Да, вы можете заказать такси через наш сайт
                <a class="font-medium underline" href="https://play.google.com/store/apps/details?id=com.gram.client"
                  >m.gram.tj</a
                >, или позвонив на наш короткий номер 0220.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
  </div>
  <div
    v-if="showButton"
    @click="scrollToTop"
    class="fixed bottom-[20px] right-[20px]"
  >
    <button
      style="box-shadow: 0px 1px 31px 4px rgba(34, 60, 80, 0.2)"
      class="border-none p-[15px] bg-white rounded-full"
    >
      <div class="flex">
        <img class="-rotate-90 w-[25px]" src="@/assets/img/arrow.svg" alt="" />
      </div>
    </button>
  </div>
  <!-- <FooterVue></FooterVue> -->
</template>

<script>
import FooterVue from "@/components/layouts/Footer.vue";
export default {
  name: "ride",
  components: {
    FooterVue,
  },
  data() {
    return {
      apphover1: false,
      showButton: false,
      registerHover1: false,
      formPage: "1",
      firstQuestion: false,
      currentSlideIndex: 0,
      number: 1,
      secondQuestion: false,
      thirdQuestion: false,
    };
  },
  mounted() {
    window.scrollTo({ top: 0 });
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.showButton = window.scrollY > 700;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.showText = false;
    },
    prevSlide() {
      if (this.currentSlideIndex > 0) {
        this.currentSlideIndex--;
        this.click();
      }
    },
    nextSlide() {
      if (this.currentSlideIndex < 1) {
        this.currentSlideIndex++;
        this.clicknumber();
      }
    },
    clicknumber() {
      if (this.number < 2) {
        this.number++;
      }
    },
    click() {
      if (this.number > 0) {
        this.number--;
      }
    },
  },
};
</script>

<style scoped>
.containers {
  margin: 0 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.p .w .d .f ~ .svg:hover {
  transform: translateX();
}
</style>